<template>
    <div class="orders">
        <van-nav-bar
            title="我的订单"
            fixed
            left-arrow
            @click-left="$router.push({name: 'user'})"
        />
        <div class="list">
            <div class="item" v-for="o,i in orders" :key="'oi'+i">
                <div class="order-id">
                    {{ o.order_id }}
                </div>
                <div class="flex">
                    <div class="image">
                        <img :src="courseImage(o.goods_image)" alt="">
                    </div>
                    <div class="title">
                        {{o.goods_title}}
                    </div>
                    <div class="btns">
                        <van-button v-if="o.pay_status==1" type="primary" size="mini">已支付</van-button>
                        <van-button v-else type="warning" size="mini" @click="payOrder(o)">支付</van-button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
import cfg from "../config.js";
import { Toast } from 'vant';
export default {
    name: 'Orders',
    data(){
        return {
            payCfg: {},
            orders: []
        }
    },
    components:{
    },
    mounted(){
        this.getData();
    },
    methods:{
        courseImage(url){
            if(url){
                return /^http/.test(url) ? url : cfg._ASSETS_HOST + url;
            }else{
                return require("../assets/logo.png");
            }
        },
        getData(){
            // let _this = this;
            this.$http.call(this.$http.api.orders).then(res=>{
                let resp = res.data;
                console.log(resp);
                if(resp.code=='0'){
                    this.orders = resp.data;
                }else{
                    Toast(resp.msg);
                    if(resp.code == '500'){
                        setTimeout(()=>{
                            this.$router.push({name:'login'});
                        },2000);
                    }
                } 
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            });
        },
        payOrder(o){
            console.log(o)
            let _this = this;
            this.$http.call(this.$http.api.payOrder,{
                data: {
                    order_id: o.order_id,
                }
            }).then(res=>{
                let resp = res.data;
                console.log(resp);
                if(resp.code=='0'){
                    _this.payCfg = resp.data.config;
                    // 使用WeixinJSBridge唤起微信支付
                    if (typeof WeixinJSBridge == "undefined") {
                        if (window.document.addEventListener) {
                            window.document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
                        } else if (window.document.attachEvent) {
                            window.document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
                            window.document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
                        }
                    } else {
                        _this.onBridgeReady(_this.payCfg);
                    }

                }else{
                    Toast(resp.msg);
                    if(resp.code == '500'){
                        setTimeout(()=>{
                            this.$router.push({name:'login'});
                        },2000);
                    }
                } 
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            });
        },
        onBridgeReady(cfg) {
            console.log(cfg);
            WeixinJSBridge.invoke('getBrandWCPayRequest', {
                ...cfg
            },
            function(res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    console.log("支付成功")
                }
            });
        },
  
    }
}
</script>
<style scoped lang="less">
.orders{
    padding: 65px 0;

    .list {
        .item {
            padding: 5px 10px;
            margin-bottom: 15px;
            background: #fff;
            .order-id {
                color: #999;
                padding-bottom: 5px;
            }
            .flex {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                position: relative;
                .image {
                    img {
                        height: 75px;
                    }
                }
                .title {
                    padding-left: 5px;
                    text-align: left;
                    flex: 1;
                }
                .btns {
                    position: absolute;
                    bottom: 0; right: 0;
                }
            }
            
        }

    }
    
}


</style>
